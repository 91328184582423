jQuery(document).ready(function ($) {
  var robotCheck = 0;
  $('#nieuwsbrief').on('click change blur', function () {
    if ($(this).is(':checked')) {
      $('#checkbox1').addClass('checked');
    } else {
      $('#checkbox1').removeClass('checked');
    }
  });
  $('#akkoord').on('click change blur', function () {
    if ($(this).is(':checked')) {
      $('#checkbox2').addClass('checked');
    } else {
      $('#checkbox2').removeClass('checked');
    }
  });
  $('#email').on('input change focus', function () {
    robotCheck = 3956;
  });
  $('#register_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      $('body').css('cursor', 'wait');
      $('#error-message').text('');
      $('#register_form button .text').hide();
      $('#register_form button #loading').show();
      $('#register_form button').attr('disabled', 'true');
      //set cursor to wait
      $('body').css('cursor', 'wait');
      var that = $('#register_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};

      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });

      //check if #toestemming is checked
      if ($('#akkoord').is(':checked')) {
        data.akkoord = 'yes';
        data.nieuwsbrief = 'no';
        if ($('#nieuwsbrief').is(':checked')) {
          data.nieuwsbrief = 'yes';
        }

        if (robotCheck === 3956) {
          $.ajax({
            url: url,
            type: method,
            data: data,
            success: function (response) {
              response = JSON.parse(response);

              if (response === 'success') {
                $('#error-message').hide();
                //replace #main with the html of response.prize_html
                $('#result-container').html(
                  '<p class="text-2xl text-mcdo-yellow text-center">Bedankt voor je deelname!<br/> Indien je hebt gewonnen ontvang je een mailtje na de wedstrijd.</p>'
                );
                $('#terms').hide();
              }
              $('#register_form button .text').show();
              $('#register_form button #loading').hide();
              $('#register_form button').removeAttr('disabled');
              $('body').css('cursor', 'default');
            },
            error: function (response) {
              $('#register_form button .text').show();
              $('#register_form button #loading').hide();
              $('#register_form button').removeAttr('disabled');
              $('#error-message').text('Mislukt, probeer nogmaals.');
              console.log(response);
            },
          });
        } else {
          $('#register_form button #loading').hide();
          $('#register_form button .text').show();
          $('#register_form button').removeAttr('disabled');
          $('body').css('cursor', 'default');
        }
      } else {
        data.akkoord = 'no';
        $('#register_form button #loading').hide();
        $('#register_form button .text').show();
        $('#register_form button').removeAttr('disabled');
        $('body').css('cursor', 'default');
        $('#error-message').text(
          'Gelieve akkoord te gaan met de wedstrijdvoorwaarden.'
        );
      }
    });
});
